
.imageContainer {
  width: 169px;
  height: 169px;
  border: 3px solid #fff;
  border-radius: 50%;
  /*box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.50);*/
  position: absolute;
  top: -100px;
  left: calc(50% - 84.5px);
  overflow: hidden;
  background-color: #fff;
}

.name {
  font-size: 1.7rem;
  font-weight: bold;
  color: #0F3371;
  margin-top: 3rem;
}

.position {
  font-size: 1.2rem;
  color: #000;
}

.review {
  font-size: 1.2rem;
  font-weight: 500;
  color: #2d2d2d;
  margin: 1rem 0;
}

.screenContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-top: 100px;

  /*height: 100vh;*/
  /*width: 100%;*/
}

.personContainer {
  width: 300px;
  height: 480px;
  background: #eaeaea;
  margin: 10px;
  padding: 30px;
  position: relative;
  border-radius: 10px;
}

.personContainer a{
    text-decoration: underline;
    color: #0F3371;
}

.star {
  color: #ffaf33;
}

.carousel {
  :global .carousel-slider {
    display: flex;
    align-items: center;
  }
}

.testimonialsContainer{
  width: 70%;
  margin: 60px auto;
}

@media only screen and (max-width: 900px) {
  .testimonialsContainer{
    width: 100%;
    margin: 0px auto;
  }

  .testimonialsContainer h3 {
    font-size: 2rem;
  }
}
