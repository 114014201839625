.container{
    display: flex;
    width:100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 5rem;
}

.innerContainer{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    color: #555555;
    align-items: center;
}

.primaryText{
    font-size: 2rem;
    font-weight: bold;
    text-transform: capitalize;
    line-height: initial;
    position: relative;
    margin-bottom: 30px
}

.contentText{
    font-size: 18px;
    line-height: 30px;
    width: 600px
}

.text{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.image{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    justify-content: space-between;
}

/* tablets */
@media only screen and (max-width: 1260px) {
    .image img{
        width: auto;
        height: 170px;
    }

    .contentText{
        width:100%;
    }

    .primaryText{
        font-size: 2rem;
    }

    .innerContainer{
        justify-content: space-evenly;
    }

    .container{
        height: auto;
    }

    .text, .image{
        flex: initial;
    }
}

/* mobiles */
@media only screen and (max-width: 900px) {
    .image img{
        padding: 10px 0 10px 0;
        width: auto;
        height: 230px;
    }

    .contentText{
        width:100%;
    }

    .primaryText{
        margin-top:20px;
        font-size: 2rem;
    }

    .innerContainer{
        justify-content: space-evenly;
        flex-direction: column-reverse !important;
    }

    .container{
        height: auto;
    }

    .text, .image{
        flex: initial;
        max-width:100%;
    }

    .text{
        padding:0 15px;
    }
}
