.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -82px;
}

.headerInfoInner {
    margin: 100px auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.headerBackground {
    margin-top: -90px;
    padding: 0px;
    /*width: 1920px;*/
    /*width: 100%;*/
    /*height: 1033px;*/
    width: inherit;
    opacity: 0;
    position: absolute;
    z-index: -1;

}

.headerSlideNavigation, .headerSlideNavigationMobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 2;
    margin-top: 40px;
}

.headerSlideNavigationMobile{
    display: none;
}

.headerSlideNavigationBall {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #27809d;
    opacity: 0.5;
    margin: 0px 10px;
    cursor: pointer;
}

.headerSlideNavigationBallCurrent {
    border: 2px solid #50c533;
    background: #50c533;
    box-shadow: 0px 0px 5px 0px #50c533;
}

.headerInto {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 90px;
    height: 895px;
    position: absolute;
    top: 0px;
    z-index: 1;
}

.headerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 90px;
    height: 950px;
}

.headerInfoRight {
    z-index: 1;
}

.mainText {
    color: #0E4D73;
    font-size: 50px;
    line-height: 60px;
}

.featureSubject {
    font-size: 26px;
    margin-top: 20px;
    line-height: 30px;
    margin-bottom: 0px;
    font-weight: 400;
}

.featureDescription {
    margin-top: 10px;
    color: #405578;
    font-size: 18px;
    line-height: 25px;
    width: 500px;
}

.learnMoreBtn {
    font-size: 22px;
    margin-top: 40px;
    border-radius: 10px;
    padding: 7px 50px;
    text-transform: capitalize;
}

.learnMoreBtn:hover {
    background: #123c85;
}

.getStartedContainer {
    width: 100%;
    text-align: center;
    padding: 80px;
}

/* mobile */
@media only screen and (max-width: 900px) {
    .headerInto {
        padding-top: 0px;
        /*height: 495px;*/
    }

    .headerBackground {
        display: none;
    }

    .mainText {
        font-size: 30px;
        line-height: 40px;
    }

    .headerInfoInner {
        width: 100%;
        align-items: stretch;
        flex-direction: column;
    }

    .headerSlideNavigation {
        justify-content: center;
        display:none;
    }

    .headerSlideNavigationMobile {
        display: flex;
        margin-top: 10px !important;
        margin-bottom: 10px;
    }

    .learnMoreBtn {
        font-size: 15px;
        margin-top: 30px;
        border-radius: 10px;
        padding: 7px 30px;
        text-transform: capitalize;
    }

    .headerContainer {
        height: 500px;
        justify-content: flex-end;
    }

    .featureDescription {
        width: 100%;
    }
}


@media only screen and (max-width: 1500px) {
    .headerInfoInner{
        transform: scale(0.85);
        margin: 30px auto;
    }
}


@media only screen and (max-width: 1400px) {
    .headerInfoInner{
        transform: scale(0.8);
        margin: 0px auto;
    }

    .headerContainer{
        height: 795px;
    }
}

@media only screen and (max-width: 1350px) {
    .headerInfoInner{
        transform: scale(0.75);
        margin: 0px auto;
    }

    .headerContainer{
        height: 695px;
    }
}


@media only screen and (max-width: 1200px) {
    .headerInfoInner{
        transform: scale(0.6);
        margin: 0px auto;
        margin-left: -100px;
        margin-top: -50px;
    }

    .headerContainer{
        height: 595px;
    }
}


@media only screen and (max-width: 1000px) {
    .headerInfoInner{
        transform: scale(0.6);
        margin-left: -100px;
        margin-top: -50px;
        width: 100%;
    }

    .headerContainer{
        height: 495px;
    }
}

@media only screen and (max-width: 900px) {
    .headerInfoInner{
        transform: scale(1);
        margin: 100px auto;
    }

    .headerContainer{
        height: 495px;
    }
}

